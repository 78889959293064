import * as React from "react";
import {forwardRef} from "react";

import styles from './Senha.module.css';
import SenhaProps from './SenhaProps';
import {Password} from "primereact/password";

const Senha = forwardRef((props: SenhaProps, inputRef?: any) => {
    return (
        <>
            <div className={`${styles.espacamento} p-fluid grid`}>
                <div className={props.fullSize ? "field col-12 md:col-12": "field col-12 md:col-4"}>
                    <label className={styles.label} htmlFor={props.id}>{props.label}</label>
                    <span>
                        <Password id={props.id} inputId={props.id} value={props.value ? props.value : ''}
                                  ref={inputRef !== undefined ? inputRef : null}
                                  tooltip={props.tooltip ? props.tooltip : undefined}
                                  tooltipOptions={props.tooltipPosition ? {position: props.tooltipPosition} : undefined}
                                  aria-describedby={`${props.id}-message`}
                                  style={{width: `${props.size}`}}
                                  placeholder={props.placeholder}

                                  toggleMask={props.mostrarSenha ? props.mostrarSenha : true}
                                  promptLabel={props.promptLabel ? props.promptLabel : 'Informe uma senha'}
                                  weakLabel={props.weakLabel ? props.weakLabel : 'Senha muito fraca'}
                                  mediumLabel={props.mediumLabel ? props.mediumLabel : 'Senha não muito forte'}
                                  strongLabel={props.strongLabel ? props.strongLabel : 'Senha forte'}

                                  required={props.required}
                                  keyfilter={props.keyFilter}
                                  maxLength={props.maxLength === undefined ? 255 : props.maxLength}
                                  onBlur={props.onBlurHandler}
                                  onFocus={props.onFocusHandler}
                                  onChange={props.onChangeHandler}/>
                    </span>
                </div>
            </div>
        </>
    );

});

export default Senha;