import React, {createContext, useRef} from "react";
import {Toast} from "primereact/toast";
import {MensagemContextType} from "./MensagemContextType";

export const MensagemContext = createContext<MensagemContextType | null>(null);

export const MensagemProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
    const toast = useRef<Toast>(null);

    const error = (mensagem: string, permanente?: boolean, tempo?: number): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'error', summary: 'Erro', sticky: permanente,
                closable: true, detail: mensagem, life: tempo ? tempo : 5000, style: {color: 'black'}});
        }
    }

    const errorHtml = (mensagem: any, permanente?: boolean, tempo?: number): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'error', summary: 'Erro', sticky: permanente,
                closable: true, content: mensagem, life: tempo ? tempo : 5000, style: {color: 'black'}});
        }
    }

    const info = (mensagem: string, permanente?: boolean, tempo?: number): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'info', summary: 'Atenção', sticky: permanente,
                closable: true, detail: mensagem, life: tempo ? tempo : 5000, style: {color: 'black'}});
        }
    }

    const infoHtml = (mensagem: any, permanente?: boolean, tempo?: number): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'info', summary: 'Atenção', sticky: permanente,
                closable: true, content: mensagem, life: tempo ? tempo : 5000, style: {color: 'black'}});
        }
    }

    return (
        <MensagemContext.Provider value={{info, infoHtml, error, errorHtml}}>
            { children }
            <Toast ref={toast} />
        </MensagemContext.Provider>
    );
}