import React, {useCallback, useContext, useEffect, useRef, useState} from "react";

import EditView from "../../component/EditView";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";
import Banner from "../../model/Banner";
import rest from "../../utils/EnvConfig";
import {RadioButton} from "primereact/radiobutton";
import {BannerTipo} from "../../model/BannerTipo";
import styles from "./Banner.module.css";
import Texto from "../../component/form/texto/Texto";
import {FileUpload, FileUploadFile, FileUploadSelectEvent} from "primereact/fileupload";
import Calendario from "../../component/form/calendario/Calendario";
import Combo from "../../component/form/combo/Combo";
import Restaurante from "../../model/Restaurante";
import {Ajax} from "../../utils/Ajax";
import {TipoUsuario} from "../../model/TipoUsuario";
import {Fieldset} from "primereact/fieldset";
import {Image} from "primereact/image";
import moment, {Moment} from "moment/moment";
import {isNumber} from "chart.js/helpers";
import {Button} from "primereact/button";

import {CardPayment, initMercadoPago} from "@mercadopago/sdk-react";
import {Dialog} from "primereact/dialog";
import {ICardPaymentBrickPayer, ICardPaymentFormData} from "@mercadopago/sdk-react/esm/bricks/cardPayment/type";
import {Accordion, AccordionTab} from "primereact/accordion";
import {PagamentoResponse} from "../../model/PagamentoResponse";
import {BannerTipoPagamento} from "../../model/BannerTipoPagamento";
import Botao from "../../component/form/botao/Botao";
import {PrimeIcons} from "primereact/api";
import {PagamentoPixResponse} from "../../model/PagamentoPixResponse";
import {BannerStatus} from "../../model/BannerStatus";
import TextoMascara from "../../component/form/mascara/TextoMascara";
import CpfCnpjUtils from "../../utils/CpfCnpjUtils";

initMercadoPago(rest.mercadoPagoPublicKey ?? "");

function BannerEdit() {
    const isHomologacao: boolean = true;

    const {error, info} = useContext(MensagemContext) as MensagemContextType;
    const [entidade, setEntidade] = useState<Banner>(new Banner());
    const [imagemSelecionada, setImagemSelecionada] = useState<FileUploadFile>();
    const {token} = useToken();
    const navigate = useNavigate();
    const [restaurantes, setRestaurantes] = useState<Restaurante[]>([]);
    const uploadRef = useRef<any>();
    const [pagamentoVisible, setPagamentoVisible] = useState(false);
    const [loadingPix, setLoadingPix] = useState(false);
    const [endLoadingSalvarFunction, setEndLoadingSalvarFunction] = useState<() => void>();
    const [cpfPix, setCpfPix] = useState<string>("");

    useEffect(() => {
        Ajax.of()
            .error(error)
            .obterTodosCustom(obterRecursoRestaurantesCorreto(), (data) => {
                setRestaurantes(data);
            });
    }, []);

    useEffect(() => {
        if (!entidade.id) {
            if (entidade.tipo && entidade.dataInicial && entidade.dataFinal) {
                atualizarValorBanner();
            }
        }
    }, [entidade.tipo, entidade.dataInicial, entidade.dataFinal]);

    const obterRecursoRestaurantesCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.restaurante}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.restaurante}/${rest.restaurantesPorUsuario}${token.usuarioUuid}`;
        }

        return "";
    }

    const editEntidade = (banner: Banner) => {
        setEntidade(banner);
        if (banner.dataInicial && banner.dataFinal) {
            const novaDataInicial: Moment = moment(banner.dataInicial, "YYYY-MM-DD");
            const novaDataFinal: Moment = moment(banner.dataFinal, "YYYY-MM-DD");
            setEntidade({ ...banner, dataInicial: novaDataInicial.toDate(),
                                             dataFinal: novaDataFinal.toDate() });
        }
    }

    const entidadeOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, [e.target.id]: e.target.value });
    }

    const tipoOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, tipo: e.value });
    }

    const atualizarValorBanner = async () => {
        await Ajax.of()
            .error(error)
            .postRegistroCustom(`${rest.server}${rest.banner}/${rest.bannerCalcularValor}`, entidade, (data) => {
                setEntidade({ ...entidade, valor: data.valor });
            });
    }

    const iniciarPagamento = useCallback(async (endLoadingFunc: () => void) => {
        setEndLoadingSalvarFunction(() => endLoadingFunc);

        await efetuarPagamentoMercadoPago(
            async () => {},
            async () => {
                error("Algo deu errado na inicialização do pagamento.");
                endLoadingFunc();
            }
        );
    }, [error, entidade]);

    const salvarCompleto = async () => {
        await salvarBanner(async () => {
             await uploadBanner(async () => {
                 info("Banner cadastrado com sucesso");

                 if ((entidade.uuid && entidade.status !== BannerStatus.REJEITADO)
                     || entidade.formaPagamento !== BannerTipoPagamento.PIX) {
                     navigate("/banner");
                     return;
                 }
             });
        });
    }

    const efetuarPagamentoMercadoPago = async (onSuccess: () => Promise<void>,
                                               onError: () => Promise<void>) => {
        if (entidade.uuid && entidade.status !== BannerStatus.REJEITADO) {
            await salvarCompleto();
            return;
        }

        setPagamentoVisible(true);
        await onSuccess();
    }

    const salvarBanner = async (onSucess: () => Promise<void>) => {
        await Ajax.of()
            .salvarRegistro(`${rest.banner}`,
                entidade, async (entidade: any) => {
                    setEntidade({...entidade, uuid: entidade.uuid});
                    await onSucess();
                });
    }

    const uploadBanner = async (onSucess: () => Promise<void>) => {
        if (entidade.tipo === BannerTipo.INTERNO) {
            uploadRef.current.upload();
        }
        await onSucess();
    }

    const validar = async (): Promise<boolean> => {
        if (entidade.tipo === BannerTipo.INTERNO && !entidade.id) {
            if (uploadRef.current && uploadRef.current.getFiles().length === 0) {
                error("Nenhum arquivo de banner foi informado.");
                return false;
            }
        }

        if (entidade.tipo === BannerTipo.EXTERNO && !entidade.fraseExterno) {
            error("Informe uma frase para o banner.");
            return false;
        }

        if (!entidade.restaurante) {
            error("Informe um restaurante.");
            return false;
        }

        if (!entidade.dataInicial) {
            error("Informe uma data para iniciar a campanha.");
            return false;
        }

        if (!entidade.dataFinal) {
            error("Informe uma data para finalizar a campanha.");
            return false;
        }

        if (entidade.dataInicial > entidade.dataFinal) {
            error("A data inicial deve ser maior que a data final.");
            return false;
        }

        if (!entidade.valor || entidade.valor === 0.0) {
            error("O valor do banner não foi calculado.");
            return false;
        }

        return true;
    }

    const isDesabilitarAoEditar = (): boolean => {
        return token.tipo === TipoUsuario.CONSUMIDOR || isNumber(entidade.id);
    }

    const limparImagemSelecionada = () => {
        if (uploadRef.current) {
            uploadRef.current.clear();
            setImagemSelecionada(undefined);
        }
    }

    const handleImageLoad = (e: any) => {
        const { naturalHeight, naturalWidth } = e.target;
        if (!isBannerProportion(naturalWidth, naturalHeight)) {
            error("O banner precisa ter uma proporção semelhante a 800 x 400.");
            limparImagemSelecionada();
        }
    };

    const isBannerProportion = (width: number, height: number): boolean => {
        const bannerAspectRatio: number = 800 / 400;
        const imageAspectRatio: number = width / height;
        const ratioTolerancia: number = 0.3;
        return Math.abs(imageAspectRatio - bannerAspectRatio) <= ratioTolerancia;
    }

    const onHidePagamento = useCallback(() => {
        if (endLoadingSalvarFunction) {
            endLoadingSalvarFunction();
        }
        if (!pagamentoVisible) return;
        setPagamentoVisible(false);
    }, [endLoadingSalvarFunction, pagamentoVisible]);

    const efetuarPagamentoPix = async () => {
        if (!cpfPix) {
            error("Informe o CPF para o pagamento por PIX.");
            return;
        }

        if (cpfPix === "") {
            error("Informe o CPF para o pagamento por PIX.");
            return;
        }

        if (!CpfCnpjUtils.isCpfCnpj(cpfPix)) {
            error("O CPF é inválido.");
            return;
        }

        setLoadingPix(true);

        const parametrosPix = {
            transaction_amount: isHomologacao ? 0.01 : entidade.valor,
            description: "Pagamento banner",
            payment_method_id: "PIX",
            payer: {
                email: token.usuarioEmail,
                identification: {
                    type: "CPF",
                    number: cpfPix
                }
            }
        }

        await Ajax.of().postRegistroCustom(`${rest.server}${rest.mercadoPago}/${rest.mercadoPagoPagamentoPix}`, parametrosPix, async (response: PagamentoPixResponse) => {
            info(response.mensagem, true);

            entidade.status = response.status;
            entidade.idTransacaoMercadoPago = response.idTransacaoMercadoPago;

            await salvarCompleto();

            setLoadingPix(false);

            window.open(response.urlPagamentoMercadoPago, "_blank");
            navigate("/banner");
        }, (err: Error) => {
            setLoadingPix(false);
            const response: PagamentoResponse = JSON.parse(err.message);
            error(response.mensagem);
            if (endLoadingSalvarFunction) endLoadingSalvarFunction();
        });
    }


    return (
        <>
            <Dialog header="Pagamento" visible={pagamentoVisible} style={{width: '50vw'}}
                    position={"top"}
                    onHide={onHidePagamento}>
                <Fieldset legend={"Forma de pagamento"}>
                    <div className="card flex">
                        <div className="flex align-items-center" style={{paddingRight: "15px"}}>
                            <RadioButton inputId="pagamentoTipoPix" name="pagamentoTipo"
                                         value={BannerTipoPagamento.PIX}
                                         disabled={entidade.idTransacaoMercadoPago !== undefined && entidade.status !== BannerStatus.REJEITADO}
                                         onChange={(e) => setEntidade({...entidade, formaPagamento: e.value})}
                                         checked={entidade.formaPagamento === BannerTipoPagamento.PIX}/>
                            <label htmlFor="pagamentoTipoPix" className="ml-2">PIX</label>
                        </div>

                        <div className="flex align-items-center" style={{paddingRight: "15px"}}>
                            <RadioButton inputId="pagamentoTipoCartaoCredito" name="pagamentoTipo"
                                         disabled={entidade.idTransacaoMercadoPago !== undefined && entidade.status !== BannerStatus.REJEITADO}
                                         value={BannerTipoPagamento.CARTAO_CREDITO}
                                         onChange={(e) => setEntidade({...entidade, formaPagamento: e.value})}
                                         checked={entidade.formaPagamento === BannerTipoPagamento.CARTAO_CREDITO}/>
                            <label htmlFor="pagamentoTipoCartaoCredito" className="ml-2">Cartão de Crédito</label>
                        </div>
                    </div>
                </Fieldset>

                {entidade.formaPagamento === BannerTipoPagamento.CARTAO_CREDITO && (
                    <>
                        <p className="m-0">
                            <Accordion activeIndex={[-1]}>
                                <AccordionTab header="Master Card">
                                    <p className="m-0">
                                        Número: 5031 4332 1540 6351
                                    </p>
                                    <p className="m-0">
                                        CVC: 123
                                    </p>
                                    <p className="m-0">
                                        Vencimento: 11/25
                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Visa">
                                    <p className="m-0">
                                        Número: 4235 6477 2802 5682
                                    </p>
                                    <p className="m-0">
                                        CVC: 123
                                    </p>
                                    <p className="m-0">
                                        Vencimento: 11/25
                                    </p>
                                </AccordionTab>
                                <AccordionTab header="American Express">
                                    <p className="m-0">
                                        Número: 3753 651535 56885
                                    </p>
                                    <p className="m-0">
                                        CVC: 1234
                                    </p>
                                    <p className="m-0">
                                        Vencimento: 11/25
                                    </p>
                                </AccordionTab>
                                <AccordionTab header="Status de pagamento">
                                    <p>
                                        Para testar diferentes resultados de pagamento, insira o status desejado no <b>nome
                                        do
                                        titular</b> do cartão:
                                    </p>
                                    <p className="m-0">
                                        Nome do titular: APRO <br/>
                                        Descrição: Simula um pagamento aprovado <br/>
                                        CPF: 12345678909
                                    </p>
                                    <br/>
                                    <p className="m-0">
                                        Nome do titular: OTHE <br/>
                                        Descrição: Simula um pagamento recusado. Qualquer nome informado também gera
                                        esse
                                        erro. <br/>
                                        CPF: 12345678909
                                    </p>
                                    <br/>
                                    <p className="m-0">
                                        Nome do titular: CONT <br/>
                                        Descrição: Simula um pagamento pendente <br/>
                                        CPF: 12345678909
                                    </p>
                                    <br/>
                                    <p className="m-0">
                                        Nome do titular: CALL <br/>
                                        Descrição: Simula um pagamento recusado com validação para autorizar <br/>
                                        CPF: 12345678909
                                    </p>
                                    <br/>
                                    <p className="m-0">
                                        Nome do titular: FUND <br/>
                                        Descrição: Simula um pagamento recusado por quantia insuficiente. <br/>
                                        CPF: 12345678909
                                    </p>
                                    <br/>
                                    <p className="m-0">
                                        Nome do titular: SECU <br/>
                                        Descrição: Simula um pagamento recusado por código CVV (código de segurança)
                                        inválido. <br/>
                                        CPF: 12345678909
                                    </p>
                                    <br/>
                                    <p className="m-0">
                                        Nome do titular: EXPI <br/>
                                        Descrição: Simula um pagamento recusado por data de vencimento do cartão
                                        inválido. <br/>
                                        CPF: 12345678909
                                    </p>
                                    <br/>
                                    <p className="m-0">
                                        Nome do titular: FORM <br/>
                                        Descrição: Simula um pagamento recusado por erro no formulário. <br/>
                                        CPF: 12345678909
                                    </p>
                                </AccordionTab>
                            </Accordion>

                            <CardPayment locale={"pt-BR"}
                                         initialization={{amount: entidade.valor!}}
                                         onSubmit={async (param: ICardPaymentFormData<ICardPaymentBrickPayer>) => {
                                             await Ajax.of().postRegistroCustom(`${rest.server}${rest.mercadoPago}/${rest.mercadoPagoPagamentoCartaoCredito}`, param, async (response: PagamentoResponse) => {
                                                 info(response.mensagem);
                                                 setPagamentoVisible(false);

                                                 entidade.status = response.status;
                                                 entidade.idTransacaoMercadoPago = response.idTransacaoMercadoPago;

                                                 Ajax.of().postRegistroCustom(`${rest.server}${rest.mercadoPago}/${rest.mercadoPagoPagamentoCartaoCreditoEmail}`, entidade);

                                                 await salvarCompleto();
                                             }, (err: Error) => {
                                                 const response: PagamentoResponse = JSON.parse(err.message);
                                                 error(response.mensagem);
                                                 setPagamentoVisible(false);
                                                 if (endLoadingSalvarFunction) endLoadingSalvarFunction();
                                             });
                                         }}
                            />
                        </p>
                    </>
                )}

                {entidade.formaPagamento === BannerTipoPagamento.PIX && (
                    <>
                        <br/>
                        <div className={styles.formatacaoTextoPix}>
                            {entidade.idTransacaoMercadoPago === undefined && (
                                <>
                                    <TextoMascara id={"cpfPix"} label={"* Informe seu CPF"}
                                                  onChangeHandler={(e: any) => setCpfPix(e.target.value)}
                                                  value={cpfPix} mask={"999.999.999-99"}/>
                                    <div>
                                        * Clique em '{`Pagar ${entidade.valor!.toLocaleString("pt-BR", {
                                        style: 'currency',
                                        currency: 'BRL'
                                    })}`}' para gerar o QR Code do PIX.
                                        <br/>
                                        * O pagamento será validado em até 10 minutos.
                                        <br/>
                                        * O banner ficará pendente até o pagamento ser autorizado.
                                    </div>
                                </>
                            )}
                        </div>

                        <div>
                            <div style={{position: "relative", float: "left", paddingRight: "10px"}}>
                                <br/>
                                <Botao id={"pagarPixButton"}
                                       loading={loadingPix}
                                       icon={PrimeIcons.MONEY_BILL}
                                       label={`Pagar ${entidade.valor!.toLocaleString("pt-BR", {
                                           style: 'currency',
                                           currency: 'BRL'
                                       })}`}
                                       onClickHandler={efetuarPagamentoPix}/>
                            </div>
                        </div>
                    </>
                )}

            </Dialog>

            <EditView title={"Banner"}
                      id={`${rest.banner}`}
                      entidade={entidade}
                      onSalvarHandler={iniciarPagamento}
                      sairAoSalvar={false}
                      onValidationHandler={validar}
                      entidadeUseStateFunction={editEntidade}>

                <Fieldset legend={"Tipo do banner"}>
                    <div className={`${styles.espacamento} p-fluid grid`}>
                        <div className="field col-12 md:col-12">
                            <label className={styles.label} htmlFor="tipoInterno">* Exibição</label>
                            <span>
                                <div className="card flex">
                                    <div className="flex flex-wrap gap-3">
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="tipoInterno" name="tipo" value={BannerTipo.INTERNO}
                                                         disabled={isDesabilitarAoEditar()}
                                                         onChange={(e) => tipoOnChangeHandler(e)}
                                                         checked={entidade.tipo === BannerTipo.INTERNO}/>
                                            <label htmlFor="tipoInterno" className="ml-2">Página do restaurante</label>
                                        </div>
                                        <div className="flex align-items-center">
                                            <RadioButton inputId="tipoExterno" name="tipo" value={BannerTipo.EXTERNO}
                                                         disabled={isDesabilitarAoEditar()}
                                                         onChange={(e) => tipoOnChangeHandler(e)}
                                                         checked={entidade.tipo === BannerTipo.EXTERNO}/>
                                            <label htmlFor="tipoExterno" className="ml-2">Página principal</label>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>

                    <div className="field col-12 md:col-12">
                        {entidade.tipo === BannerTipo.EXTERNO && (
                            <>
                                <b>* O título e a frase do banner pode sofrer alterações para se adequar com o
                                    design.</b>
                                <br/>
                                <br/>
                                <Texto id={"tituloFraseExterno"} label={"* Título do banner"}
                                       maxLength={25} fullSize={true}
                                       onChangeHandler={entidadeOnChangeHandler}
                                       value={entidade.tituloFraseExterno}/>

                                <Texto id={"fraseExterno"} label={"* Frase do banner"}
                                       maxLength={50} fullSize={true}
                                       onChangeHandler={entidadeOnChangeHandler}
                                       value={entidade.fraseExterno}/>
                            </>
                        )}

                        {entidade.tipo === BannerTipo.INTERNO && (
                            <>
                                <b>* O arquivo deve ser uma imagem (jpg ou png) de até 1 MB.</b>
                                <br/>
                                <b>* A imagem precisa obedecer a proporção de 800 x 400 com uma leve tolerância de
                                    tamanho.</b>
                                <br/>
                                <br/>
                                <div>
                                    <FileUpload mode={"basic"} ref={uploadRef} name="imagem"
                                                multiple={false}
                                                style={{position: "relative", float: "left", paddingRight: "10px"}}
                                                disabled={isDesabilitarAoEditar()}
                                                url={`${rest.server}${rest.banner}/${rest.bannerUpload}${entidade.uuid}`}
                                                accept="image/*" maxFileSize={1048576} // 1 MB
                                                chooseLabel={"Escolher uma imagem"}
                                                onValidationFail={(e: File) => error("Imagem inválida")}
                                                onSelect={(e: FileUploadSelectEvent) => setImagemSelecionada(e.files[0])}
                                                auto={false}/>

                                    {imagemSelecionada && (
                                        <Button icon="pi pi-trash" severity="danger" raised
                                                onClick={(e: any) => limparImagemSelecionada()}/>
                                    )}
                                </div>

                                {imagemSelecionada && (
                                    <>
                                        <br/>
                                        <Image src={imagemSelecionada.objectURL} onLoad={handleImageLoad} width={"300"}
                                               height={"120"}/>
                                    </>
                                )}
                            </>
                        )}

                        {entidade.tipo === BannerTipo.INTERNO && entidade.id && (
                            <>
                                <br/>
                                <br/>
                                <br/>
                                <Image
                                    src={`${rest.server}${rest.banner}/${rest.bannerObterInterno}${entidade.uuid}`}
                                    preview={true} loading={"lazy"}
                                    alt="Image" width="250" height="140"/>
                            </>
                        )}
                    </div>
                </Fieldset>

                <br/>

                <Combo values={restaurantes} id={"restaurante"} filter={true}
                       optionLabel={"nomeEstabelecimento"}
                       placeHolder={"Selecione um restaurante"}
                       onChangeHandler={entidadeOnChangeHandler}
                       label={"* Restaurante"} value={entidade.restaurante}/>

                <Calendario id={"dataInicial"} label={"* Início da campanha"}
                            minDate={new Date()}
                            onChangeHandler={entidadeOnChangeHandler}
                            disabled={isDesabilitarAoEditar()}
                            value={entidade.dataInicial}/>

                <Calendario id={"dataFinal"} label={"* Fim da campanha"}
                            minDate={new Date()}
                            onChangeHandler={entidadeOnChangeHandler}
                            disabled={isDesabilitarAoEditar()}
                            value={entidade.dataFinal}/>

                <div className={`${styles.totalPagar} field grid`}>
                    <label className={` col-fixed`}
                           style={{width: "150px"}}>Total</label>
                    <div className="col">
                        {entidade.valor?.toLocaleString("pt-BR", {
                            style: 'currency',
                            currency: 'BRL'
                        })}
                    </div>
                </div>

            </EditView>
        </>
    );
}

export default BannerEdit;