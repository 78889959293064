import React from 'react';

function Bemvindo() {
    return (
        <>
            <div className="card">
                <h2>Seja bem-vindo</h2>
                <h6>Versão: 1.0.34</h6>
            </div>
        </>
    );
}

export default Bemvindo;