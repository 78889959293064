import ListView from "../../component/ListView";
import {Column} from "primereact/column";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";
import rest from "../../utils/EnvConfig";
import moment, {Moment} from "moment";
import Banner from "../../model/Banner";
import {TipoUsuario} from "../../model/TipoUsuario";


function BannerList() {
    const {token} = useToken();
    const navigate = useNavigate();

    const obterRecursoCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.banner}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.banner}/${rest.bannerPorUsuario}${token.usuarioUuid}`;
        }

        return "";
    }

    const dataInicialTemplate = (banner: Banner): string => {
        if (banner.dataInicial) {
            const novaData: Moment = moment(banner.dataInicial, "YYYY-MM-DD");
            return novaData.format("DD/MM/YYYY");
        }
        return "";
    };

    const dataFinalTemplate = (banner: Banner): string => {
        if (banner.dataFinal) {
            const novaData: Moment = moment(banner.dataFinal, "YYYY-MM-DD");
            return novaData.format("DD/MM/YYYY");
        }
        return "";
    };

    const valorTemplate = (banner: Banner): string => {
        if (banner.valor) {
            return banner.valor?.toLocaleString("pt-BR", {
                style: 'currency',
                currency: 'BRL'
            });
        }
        return "";
    };

    return (
        <>
            <ListView title={"Banner"}
                      restCustom={obterRecursoCorreto()}
                      visibleExcluir={(entidade: Banner) => token.tipo === TipoUsuario.ADMINISTRADOR}
                      id={`${rest.banner}`}>
                <Column field="tipo" header="Tipo" sortable />
                <Column field="restaurante.nomeEstabelecimento" header="Restaurante" sortable />
                <Column field="dataInicial" header="Início da campanha" body={dataInicialTemplate}  sortable />
                <Column field="dataFinal" header="Fim da campanha" body={dataFinalTemplate} sortable />
                <Column field="valor" header="Valor" body={valorTemplate} sortable />
                <Column field="status" header="Situação" sortable />
                <Column field="formaPagamento" header="Forma de pagamento" sortable />
            </ListView>
        </>
    );
}

export default BannerList;