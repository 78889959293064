import React, {useContext, useEffect, useState} from "react";

import EditView from "../../component/EditView";
import Texto from "../../component/form/texto/Texto";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import FormUtils from "../../utils/FormUtils";
import {TooltipPosition} from "../../component/form/TooltipPosition";
import {Usuario} from "../../model/Usuario";
import TextoMascara from "../../component/form/mascara/TextoMascara";
import Senha from "../../component/form/senha/Senha";
import Combo from "../../component/form/combo/Combo";
import {TipoUsuario} from "../../model/TipoUsuario";
import CpfCnpjUtils from "../../utils/CpfCnpjUtils";
import useToken from "../../hook/useToken";
import {useNavigate} from "react-router-dom";
import rest from "../../utils/EnvConfig";

function UsuarioEdit() {
    const {error, errorHtml} = useContext(MensagemContext) as MensagemContextType;
    const [entidade, setEntidade] = useState<Usuario>({});
    const [confirmaSenha, setConfirmaSenha] = useState<string>("");
    const tiposUsuarios: TipoUsuario[] = [TipoUsuario.ADMINISTRADOR, TipoUsuario.CONSUMIDOR, TipoUsuario.CLIENTE];
    const {token} = useToken();
    const navigate = useNavigate();

    useEffect(() => {
        if (token && token.tipo !== TipoUsuario.ADMINISTRADOR) {
            navigate("/sem-permissao");
            return;
        }
    }, []);

    const editEntidade = (data: Usuario) => {
        setEntidade(data);
    }

    const entidadeOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, [e.target.id]: e.target.value });
    }

    const validar = async (): Promise<boolean> => {
        if (!entidade.nome) {
            FormUtils.invalidarComponente(error, "Nome é obrigatório.");
            return false;
        }

        if (!entidade.cpf) {
            FormUtils.invalidarComponente(error, "CPF é obrigatório.");
            return false;
        }

        if (!CpfCnpjUtils.isCpfCnpj(entidade.cpf)) {
            FormUtils.invalidarComponente(error, "CPF inválido.");
            return false;
        }

        if (!entidade.email) {
            FormUtils.invalidarComponente(error, "E-mail é obrigatório.");
            return false;
        }

        if (entidade.email) {
            const regexFormatoEmail: RegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            const testeFormatoEmail: boolean = regexFormatoEmail.test(entidade.email);

            if (!testeFormatoEmail) {
                FormUtils.invalidarComponente(error, "Formato de e-mail inválido.");
                return false;
            }
        }

        if (!entidade.id && !entidade.senha) {
            FormUtils.invalidarComponente(error, "Senha é obrigatório.");
            return false;
        }

        if (entidade.senha) {
            if (entidade.senha !== confirmaSenha) {
                FormUtils.invalidarComponente(error, "As senhas não estão iguais.");
                return false;
            }

            const regexSenhaForte: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
            const testeSenhaForte: boolean = regexSenhaForte.test(entidade.senha);

            if (!testeSenhaForte) {
                errorHtml((
                    <div>Sua senha não está forte. Por razões de segurança, crie uma senha forte contendo ao menos
                        um dos seguintes padrões:
                        <br/><br/>
                        <li>Pelo menos 8 caracteres</li>
                        <li>Letra maiúscula</li>
                        <li>Letra minúscula</li>
                        <li>Número</li>
                        <li>Símbolo (ex: $#@)</li>
                    </div>
                ));
                return false;
            }
        }

        return true;
    }

    return (
        <>
            {token && token.tipo === TipoUsuario.ADMINISTRADOR && (
                <EditView title={"Usuário"}
                          id={`${rest.usuario}`}
                          entidade={entidade}
                          onValidationHandler={validar}
                          entidadeUseStateFunction={editEntidade}>

                    <Combo values={tiposUsuarios} id={"tipo"} label={"* Tipo do usuário"}
                           onChangeHandler={entidadeOnChangeHandler}
                           disabled={entidade.id !== undefined}
                           placeholder={"Selecione um tipo de usuário"}
                           value={entidade.tipo}/>

                    <Texto id={"nome"} value={entidade.nome}
                           label={"* Nome"} size={"100%"} maxLength={100}
                           placeholder={"Ex: João da Silva"}
                           tooltip={"Informe o nome do usuário"} tooltipPosition={TooltipPosition.TOP}
                           onChangeHandler={entidadeOnChangeHandler}/>

                    <TextoMascara id={"cpf"} value={entidade.cpf}
                                  label={"* CPF"} size={"100%"}
                                  mask={"999.999.999-99"}
                                  placeholder={"Ex: 000.000.000-00"}
                                  tooltip={"Informe o CPF do usuário"} tooltipPosition={TooltipPosition.TOP}
                                  onChangeHandler={entidadeOnChangeHandler}/>

                    <Texto id={"enderecoCompleto"} value={entidade.enderecoCompleto}
                           label={"Endereço completo"} size={"100%"} maxLength={255}
                           placeholder={"Ex: Rua Getúlio Vargas, 123, Zona 2, Maringá, PR"}
                           tooltip={"Informe o endereço completo"} tooltipPosition={TooltipPosition.TOP}
                           onChangeHandler={entidadeOnChangeHandler}/>

                    <Texto id={"email"} value={entidade.email}
                           label={"* E-mail"} size={"100%"} maxLength={255}
                           placeholder={"Ex: joao.da.silva@gmail.com"}
                           disabled={entidade.id !== undefined}
                           tooltip={"Informe o e-mail do usuário"} tooltipPosition={TooltipPosition.TOP}
                           onChangeHandler={entidadeOnChangeHandler}/>

                    <Senha id={"senha"} value={entidade.senha}
                           label={entidade.id ? "Senha" : "* Senha"} size={"100%"} maxLength={255}
                           placeholder={"Ex: senhaForteAqui"}
                           tooltip={"Informe a senha do usuário"} tooltipPosition={TooltipPosition.TOP}
                           onChangeHandler={entidadeOnChangeHandler}/>

                    <Senha id={"confirmarSenha"} value={confirmaSenha}
                           label={entidade.id ? "Confirmação de senha" : "* Confirmação de senha"} size={"100%"}
                           maxLength={255}
                           placeholder={"Ex: senhaForteAqui"}
                           tooltip={"Informe a confirmação da sua senha"} tooltipPosition={TooltipPosition.TOP}
                           onChangeHandler={(e: any) => setConfirmaSenha(e.target.value)}/>

                </EditView>
            )}
        </>
    );
}

export default UsuarioEdit;