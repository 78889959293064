import {Entidade} from "./Entidade";
import {TipoUsuario} from "./TipoUsuario";
import {UsuarioPlano} from "./UsuarioPlano";

export class Usuario extends Entidade {

    nome?: string;
    cpf?: string;
    enderecoCompleto?: string;
    email?: string;
    senha?: string;
    tipo?: TipoUsuario;
    plano?: UsuarioPlano = UsuarioPlano.GRATIS;

}
