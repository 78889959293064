import {Entidade} from "./Entidade";
import {BannerTipo} from "./BannerTipo";
import Restaurante from "./Restaurante";
import {BannerStatus} from "./BannerStatus";
import {BannerTipoPagamento} from "./BannerTipoPagamento";

export default class Banner extends Entidade {

    restaurante?: Restaurante;
    tipo?: BannerTipo = BannerTipo.INTERNO;
    formaPagamento?: BannerTipoPagamento = BannerTipoPagamento.NENHUM;
    tituloFraseExterno?: string;
    fraseExterno?: string;
    dataInicial?: Date;
    dataFinal?: Date;
    valor?: number = 0.00;
    status?: BannerStatus;
    idTransacaoMercadoPago?: string;

}