import React, {useRef, useState} from 'react';

import styles from './Login.module.css';
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import PropTypes from "prop-types";
import {Autenticacao} from "../../model/Autenticacao";
import {AutenticacaoToken} from "../../model/AutenticacaoToken";
import rest from "../../utils/EnvConfig";
import {Toast} from "primereact/toast";
import {TipoUsuario} from "../../model/TipoUsuario";
import {Card} from "primereact/card";
import Switch from "../../component/form/switch/Switch";
import Texto from "../../component/form/texto/Texto";
import {TooltipPosition} from "../../component/form/TooltipPosition";
import TextoMascara from "../../component/form/mascara/TextoMascara";
import Senha from "../../component/form/senha/Senha";
import {Usuario} from "../../model/Usuario";
import FormUtils from "../../utils/FormUtils";
import CpfCnpjUtils from "../../utils/CpfCnpjUtils";
import {Ajax} from "../../utils/Ajax";

enum Funcionalidade {
    LOGIN = "Login",
    NOVA_CONTA = "Nova conta",
    ESQUECEU_SENHA = "Esqueceu senha",
}

interface EsqueceuSenha {
    cpf?: string;
    email?: string;
}

interface NovaContaResponse {
    mensagem: string;
    urlPagamentoRecorrente?: string;
}

function Login(props: any) {
    const toast = useRef<Toast>(null);
    const [autenticacao, setAutenticacao] = useState<Autenticacao>(new Autenticacao());
    const [loading, setLoading] = useState<boolean>(false);
    const [salvarSenha, setSalvarSenha] = useState<boolean>(false);
    const [funcionalidade, setFuncionalidade] = useState<Funcionalidade>(Funcionalidade.LOGIN);
    const [entidade, setEntidade] = useState<Usuario>(new Usuario());
    const [esqueceuSenha, setEsqueceuSenha] = useState<EsqueceuSenha>({});
    const [confirmaSenha, setConfirmaSenha] = useState<string>("");

    const error = (mensagem: string): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'error', summary: 'Erro', detail: mensagem, life: 5000, style: {color: 'black'}});
        }
    }

    const errorHtml = (mensagem: any): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'error', summary: 'Erro', content: mensagem, life: 5000, style: {color: 'black'}});
        }
    }

    const info = (mensagem: string, permanente?: boolean): void => {
        if (toast.current !== null) {
            toast.current.show({severity:'info', summary: 'Atenção',
                closable: true, detail: mensagem, sticky: permanente, life: 5000, style: {color: 'black'}});
        }
    }

    const autenticacaoOnChangeHandler = (e: any) => {
        setAutenticacao({ ...autenticacao, [e.target.id]: e.target.value });
    }

    const entidadeOnChangeHandler = (e: any) => {
        setEntidade({ ...entidade, [e.target.id]: e.target.value });
    }

    const esqueceuSenhaOnChangeHandler = (e: any) => {
        setEsqueceuSenha({ ...esqueceuSenha, [e.target.id]: e.target.value });
    }

    const autenticarOnKeyDown = async (e: any) => {
        if (e && e.keyCode === 13) {
            await autenticar();
        }
    }

    const switchOnChangeHandler = (e: any) => {
        setSalvarSenha(e.value);
    }

    const autenticar = async () => {
        if (!autenticacao.usuario) {
            error("E-mail é obrigatório");
            return;
        }
        
        if (!autenticacao.senha) {
            error("Senha é obrigatória");
            return;
        }
        
        setLoading(true);

        return fetch(`${rest.server}${rest.autenticacao}/${rest.autenticacaoAutenticar}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(autenticacao),
        })
        .then(async resp => {
            if (resp.ok) {
                return await resp.json() as AutenticacaoToken;
            }

            setLoading(false);

            throw new Error(await resp.text());
        })
        .then((data: AutenticacaoToken) => {
            data.usuarioEmail = autenticacao.usuario;
            data.salvarSenha = salvarSenha;
            props.setToken(data);

            if (data.tipo === TipoUsuario.CLIENTE) {
                window.location.href = "/restaurante";
            } else {
                window.location.href = "/";
            }

            setLoading(false);
        }).catch((err: Error) => {
            error(err.message);
            setLoading(false);
        });
    }

    const salvar = async () => {
        if (!entidade.nome) {
            FormUtils.invalidarComponente(error, "Nome é obrigatório.");
            return false;
        }

        if (!entidade.cpf) {
            FormUtils.invalidarComponente(error, "CPF é obrigatório.");
            return false;
        }

        if (!CpfCnpjUtils.isCpfCnpj(entidade.cpf)) {
            FormUtils.invalidarComponente(error, "CPF inválido.");
            return false;
        }

        if (!entidade.email) {
            FormUtils.invalidarComponente(error, "E-mail é obrigatório.");
            return false;
        }

        if (entidade.email) {
            const regexFormatoEmail: RegExp = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            const testeFormatoEmail: boolean = regexFormatoEmail.test(entidade.email);

            if (!testeFormatoEmail) {
                FormUtils.invalidarComponente(error, "Formato de e-mail inválido.");
                return false;
            }
        }

        if (!entidade.id && !entidade.senha) {
            FormUtils.invalidarComponente(error, "Senha é obrigatório.");
            return false;
        }

        if (entidade.senha) {
            if (entidade.senha !== confirmaSenha) {
                FormUtils.invalidarComponente(error, "As senhas não estão iguais.");
                return false;
            }

            const regexSenhaForte: RegExp = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
            const testeSenhaForte: boolean = regexSenhaForte.test(entidade.senha);

            if (!testeSenhaForte) {
                errorHtml((
                    <div>Sua senha não está forte. Por razões de segurança, crie uma senha forte contendo ao menos
                        um dos seguintes padrões:
                        <br/><br/>
                        <li>Pelo menos 8 caracteres</li>
                        <li>Letra maiúscula</li>
                        <li>Letra minúscula</li>
                        <li>Número</li>
                        <li>Símbolo (ex: $#@)</li>
                    </div>
                ));
                return false;
            }
        }

        setLoading(true);

        await Ajax.of()
            .info(info)
            .error(error)
            .postRegistroCustom(`${rest.server}${rest.autenticacao}/${rest.autenticacaoCriarConta}`, entidade,(response: NovaContaResponse) => {
                // if (response) {
                //     info(response.mensagem, true);
                // }
                //
                // if (response && response.urlPagamentoRecorrente) {
                //     window.open(response.urlPagamentoRecorrente, "_blank");
                // }
                setLoading(false);
                setFuncionalidade(Funcionalidade.LOGIN);
                setEntidade(new Usuario());
            }, () => {
                setLoading(false);
            });
    }

    const solicitarSenha = async () => {
        if (!esqueceuSenha.cpf) {
            FormUtils.invalidarComponente(error, "CPF é obrigatório.");
            return false;
        }

        if (!esqueceuSenha.email) {
            FormUtils.invalidarComponente(error, "E-mail é obrigatório.");
            return false;
        }

        setLoading(true);

        await Ajax.of()
            .error(error)
            .postRegistroCustom(`${rest.server}${rest.autenticacao}/${rest.autenticacaoEsqueceuSenha}`, esqueceuSenha,(response: any) => {
                info(response.message);
                setLoading(false);
                setFuncionalidade(Funcionalidade.LOGIN);
                setEsqueceuSenha({});
            }, () => {
                setLoading(false);
            });
    }

    return (
        <>
            {funcionalidade === Funcionalidade.LOGIN && (
                <div className={styles.divMasterLogin}>
                    <div className={`${styles.centralizacaoVertical}`}>
                        <center>
                            <Card title={"O que abre hoje"} className={`${styles.formlogin} ${styles.sombra}`}>

                                <p className="m-0">
                                    Por favor, entre com seus dados ou clique em <a href={"#"} onClick={() => setFuncionalidade(Funcionalidade.NOVA_CONTA)} className={styles.linkLogin}>Criar conta</a>.
                                </p>

                                <br />
                                <br />

                                <InputText id={"usuario"} placeholder={"E-mail"}
                                           className={styles.espacamento}
                                           onKeyDown={autenticarOnKeyDown}
                                           onChange={autenticacaoOnChangeHandler} />

                                <Password inputId={"senha"} placeholder={"Senha"}
                                          className={styles.espacamento}
                                          feedback={false}
                                          onKeyDown={autenticarOnKeyDown}
                                          onChange={autenticacaoOnChangeHandler} />

                                <br />
                                <br />

                                <Switch checked={salvarSenha} tipoLabel={"login"}
                                        onChangeHandler={switchOnChangeHandler}
                                        label={"Salvar senha"} id={"salvarSenha"} />


                                <br />
                                <Button label={"Entrar"} loading={loading}
                                        className={`${styles.espacamento} ${styles.tamanhoBotaoEntrar}`}
                                        onClick={async () => await autenticar()} />
                                <br />
                                <br />
                                <Button label={"Esqueceu a senha?"} onClick={() => setFuncionalidade(Funcionalidade.ESQUECEU_SENHA)} link/>

                            </Card>
                        </center>
                    </div>
                </div>
            )}

            {funcionalidade === Funcionalidade.NOVA_CONTA && (
                <div className={styles.divMaster}>
                    <div className={`${styles.centralizacaoVertical} ${styles.centralizar}`}>

                        <Card title={"O que abre hoje"} className={`${styles.formcriarconta} ${styles.sombra}`}>

                            <p className="m-0">
                                Por favor, preencha corretamente o formulário abaixo e aguarde a confirmação do seu
                                cadastro.
                            </p>

                            <br/>
                            <br/>

                            <Texto id={"nome"} value={entidade.nome}
                                   label={"* Nome"} fullSize={true} maxLength={100}
                                   placeholder={"Ex: João da Silva"}
                                   tooltip={"Informe o nome do usuário"} tooltipPosition={TooltipPosition.TOP}
                                   onChangeHandler={entidadeOnChangeHandler}/>

                            <TextoMascara id={"cpf"} value={entidade.cpf}
                                          label={"* CPF"} fullSize={true}
                                          mask={"999.999.999-99"}
                                          placeholder={"Ex: 000.000.000-00"}
                                          tooltip={"Informe o CPF do usuário"} tooltipPosition={TooltipPosition.TOP}
                                          onChangeHandler={entidadeOnChangeHandler}/>

                            <Texto id={"enderecoCompleto"} value={entidade.enderecoCompleto}
                                   label={"Endereço completo"} fullSize={true} maxLength={255}
                                   placeholder={"Ex: Rua Getúlio Vargas, 123, Zona 2, Maringá, PR"}
                                   tooltip={"Informe o endereço completo"} tooltipPosition={TooltipPosition.TOP}
                                   onChangeHandler={entidadeOnChangeHandler}/>

                            <Texto id={"email"} value={entidade.email}
                                   label={"* E-mail"} fullSize={true} maxLength={255}
                                   placeholder={"Ex: joao.da.silva@gmail.com"}
                                   tooltip={"Informe o e-mail do usuário"} tooltipPosition={TooltipPosition.TOP}
                                   onChangeHandler={entidadeOnChangeHandler}/>

                            <Senha id={"senha"} value={entidade.senha}
                                   label={entidade.id ? "Senha" : "* Senha"} fullSize={true} maxLength={255}
                                   placeholder={"Ex: senhaForteAqui"}
                                   tooltip={"Informe a senha do usuário"} tooltipPosition={TooltipPosition.TOP}
                                   onChangeHandler={entidadeOnChangeHandler}/>

                            <Senha id={"confirmarSenha"} value={confirmaSenha}
                                   label={entidade.id ? "Confirmação de senha" : "* Confirmação de senha"}
                                   fullSize={true}
                                   maxLength={255}
                                   placeholder={"Ex: senhaForteAqui"}
                                   tooltip={"Informe a confirmação da sua senha"}
                                   tooltipPosition={TooltipPosition.TOP}
                                   onChangeHandler={(e: any) => setConfirmaSenha(e.target.value)}/>

                            {/*<Fieldset legend={"Plano"}>*/}
                            {/*    <div className="card flex">*/}
                            {/*        <div className="flex align-items-center" style={{paddingRight: "15px"}}>*/}
                            {/*            <RadioButton inputId="planoGratis" name="plano"*/}
                            {/*                         value={UsuarioPlano.GRATIS}*/}
                            {/*                         onChange={(e) => setEntidade({...entidade, plano: e.value})}*/}
                            {/*                         checked={entidade.plano === UsuarioPlano.GRATIS}/>*/}
                            {/*            <label htmlFor="planoGratis" className="ml-2">Grátis</label>*/}
                            {/*        </div>*/}

                            {/*        <div className="flex align-items-center" style={{paddingRight: "15px"}}>*/}
                            {/*            <RadioButton inputId="planoPago" name="plano"*/}
                            {/*                         value={UsuarioPlano.PAGO}*/}
                            {/*                         onChange={(e) => setEntidade({...entidade, plano: e.value})}*/}
                            {/*                         checked={entidade.plano === UsuarioPlano.PAGO}/>*/}
                            {/*            <label htmlFor="planoPago" className="ml-2">R$ 19,90 por mês</label>*/}
                            {/*        </div>*/}

                            {/*        <div className="flex align-items-center" style={{paddingRight: "15px"}}>*/}
                            {/*            <RadioButton inputId="planoPagoSemestral" name="plano"*/}
                            {/*                         value={UsuarioPlano.PAGO_SEMESTRAL}*/}
                            {/*                         onChange={(e) => setEntidade({...entidade, plano: e.value})}*/}
                            {/*                         checked={entidade.plano === UsuarioPlano.PAGO_SEMESTRAL}/>*/}
                            {/*            <label htmlFor="planoPagoSemestral" className="ml-2">R$ 110,90 semestral</label>*/}
                            {/*        </div>*/}

                            {/*        <div className="flex align-items-center" style={{paddingRight: "15px"}}>*/}
                            {/*            <RadioButton inputId="planoPagoAnual" name="plano"*/}
                            {/*                         value={UsuarioPlano.PAGO_ANUAL}*/}
                            {/*                         onChange={(e) => setEntidade({...entidade, plano: e.value})}*/}
                            {/*                         checked={entidade.plano === UsuarioPlano.PAGO_ANUAL}/>*/}
                            {/*            <label htmlFor="planoPagoAnual" className="ml-2">R$ 199,90 anual</label>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</Fieldset>*/}

                            {/*<br/>*/}

                            {/*{entidade.plano === UsuarioPlano.GRATIS && (*/}
                            {/*    <Card title={"Informações do plano: Grátis"}>*/}
                            {/*        <li>Valor 1</li>*/}
                            {/*        <li>Valor 2</li>*/}
                            {/*        <li>Valor 3</li>*/}
                            {/*    </Card>*/}
                            {/*)}*/}

                            {/*{entidade.plano !== UsuarioPlano.GRATIS && (*/}
                            {/*    <Card title={"Informações do plano: Pago"}>*/}
                            {/*    <li>Valor 1</li>*/}
                            {/*        <li>Valor 2</li>*/}
                            {/*        <li>Valor 3</li>*/}
                            {/*        <li>Valor 4</li>*/}
                            {/*        <li>Valor 5</li>*/}
                            {/*        <li>Valor 6</li>*/}
                            {/*    </Card>*/}
                            {/*)}*/}

                            <br/>

                            <Button label={"Cadastrar"} loading={loading}
                                    className={`${styles.espacamento} ${styles.tamanhoBotaoEntrar}`}
                                    onClick={async () => await salvar()}/>

                            <center>
                                <Button label={"« Voltar para autenticação"}
                                        onClick={() => setFuncionalidade(Funcionalidade.LOGIN)} link/>
                            </center>
                        </Card>

                    </div>
                </div>
            )}

            {funcionalidade === Funcionalidade.ESQUECEU_SENHA && (
                <div className={styles.divMasterLogin}>
                    <div className={`${styles.centralizacaoVertical} ${styles.centralizar}`}>
                        <Card title={"O que abre hoje"} className={`${styles.formlogin} ${styles.sombra}`}>

                            <p className="m-0">
                                Por favor, informe os dados abaixo para solicitar uma nova senha. <br/>
                                Uma nova senha forte será enviada no seu e-mail.
                            </p>

                            <br />
                            <br />

                            <TextoMascara id={"cpf"} value={esqueceuSenha.cpf}
                                          label={"* CPF"} fullSize={true}
                                          mask={"999.999.999-99"}
                                          placeholder={"Ex: 000.000.000-00"}
                                          tooltip={"Informe o CPF do usuário"} tooltipPosition={TooltipPosition.TOP}
                                          onChangeHandler={esqueceuSenhaOnChangeHandler}/>

                            <Texto id={"email"} value={esqueceuSenha.email}
                                   label={"* E-mail"} fullSize={true} maxLength={255}
                                   placeholder={"Ex: joao.da.silva@gmail.com"}
                                   tooltip={"Informe o e-mail do usuário"} tooltipPosition={TooltipPosition.TOP}
                                   onChangeHandler={esqueceuSenhaOnChangeHandler}/>



                            <br />
                            <Button label={"Solicitar nova senha"} loading={loading}
                                    className={`${styles.espacamento} ${styles.tamanhoBotaoEntrar}`}
                                    onClick={async () => await solicitarSenha()} />
                            <br />
                            <br />
                            <center>
                                <Button label={"« Voltar para autenticação"}
                                        onClick={() => setFuncionalidade(Funcionalidade.LOGIN)} link/>
                            </center>

                        </Card>
                    </div>
                </div>
            )}
            <Toast ref={toast}/>
        </>
    );
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}

export default Login;