const rest = {
    server: process.env.REACT_APP_REST_SERVER,

    mercadoPagoPublicKey: process.env.REACT_APP_MERCADO_PAGO_PUBLICKEY,

    mercadoPago: process.env.REACT_APP_REST_MERCADO_PAGO,
    tipoRestaurante: process.env.REACT_APP_REST_TIPO_RESTAURANTE,
    diferencial: process.env.REACT_APP_REST_DIFERENCIAL,
    tipoServico: process.env.REACT_APP_REST_TIPO_SERVICO,
    banner: process.env.REACT_APP_REST_BANNER,
    bannerUpload: process.env.REACT_APP_REST_BANNER_UPLOAD,
    bannerCalcularValor: process.env.REACT_APP_REST_BANNER_CALCULAR,
    bannerObterInterno: process.env.REACT_APP_REST_BANNER_OBTER_INTERNO,
    bannerPorUsuario: process.env.REACT_APP_REST_BANNER_POR_USUARIO,
    diaria: process.env.REACT_APP_REST_DIARIA,
    diariaCandidato: process.env.REACT_APP_REST_DIARIA_CANDIDATO,
    candidatosPorDiaria: process.env.REACT_APP_REST_DIARIA_CANDIDATO_OBTER_POR_DIA,
    candidatosPorDiariaSemFirebase: process.env.REACT_APP_REST_DIARIA_CANDIDATO_OBTER_SEM_FIREBASE,
    diariaCandidatoAprovar: process.env.REACT_APP_REST_DIARIA_CANDIDATO_APROVAR,
    restaurante: process.env.REACT_APP_REST_RESTAURANTE,
    restauranteImagem: process.env.REACT_APP_REST_RESTAURANTE_IMAGEM,
    restauranteLogo: process.env.REACT_APP_REST_RESTAURANTE_LOGO,
    restaurantesPorUsuario: process.env.REACT_APP_REST_RESTAURANTE_POR_USUARIO,
    grupoComida: process.env.REACT_APP_REST_GRUPO_COMIDA,
    grupoComidaPorCardapio: process.env.REACT_APP_REST_GRUPO_COMIDA_POR_CARDAPIO,
    grupoBebida: process.env.REACT_APP_REST_GRUPO_BEBIDA,
    grupoBebidaPorCardapio: process.env.REACT_APP_REST_GRUPO_BEBIDA_POR_CARDAPIO,

    mercadoPagoPagamentoCartaoCredito: process.env.REACT_APP_REST_MERCADO_PAGO_PAGAMENTO_CARTAOCREDITO,
    mercadoPagoPagamentoPix: process.env.REACT_APP_REST_MERCADO_PAGO_PAGAMENTO_PIX,
    mercadoPagoPagamentoCartaoCreditoEmail: process.env.REACT_APP_REST_MERCADO_PAGO_PAGAMENTO_CARTAOCREDITO_EMAIL,

    obterTodos: process.env.REACT_APP_REST_OBTER_TODOS,
    obterPorId: process.env.REACT_APP_REST_OBTER_POR_ID,
    pesquisar: process.env.REACT_APP_REST_PESQUISAR,
    salvar: process.env.REACT_APP_REST_SALVAR,
    remover: process.env.REACT_APP_REST_REMOVER,

    restauranteImagemUpload: process.env.REACT_APP_REST_RESTAURANTE_IMAGEM_UPLOAD,
    restauranteImagemObterTodos: process.env.REACT_APP_REST_RESTAURANTE_IMAGEM_OBTER_TODOS,
    restauranteImagemObterPorId: process.env.REACT_APP_REST_RESTAURANTE_IMAGEM_OBTER_POR_ID,
    restauranteImagemRemover: process.env.REACT_APP_REST_RESTAURANTE_IMAGEM_REMOVER,

    restauranteLogoUpload: process.env.REACT_APP_REST_RESTAURANTE_LOGO_UPLOAD,
    restauranteLogoObterPorId: process.env.REACT_APP_REST_RESTAURANTE_LOGO_OBTER_POR_ID,
    restauranteLogoRemover: process.env.REACT_APP_REST_RESTAURANTE_LOGO_REMOVER,

    restauranteCardapioRestauranteCompleto: process.env.REACT_APP_REST_RESTAURANTE_CARDAPIO_RESTAURANTE_COMPLETO,
    restauranteCardapioImagemUpload: process.env.REACT_APP_REST_RESTAURANTE_CARDAPIO_IMAGEM_UPLOAD,
    restauranteCardapioImagemObterPorId: process.env.REACT_APP_REST_RESTAURANTE_CARDAPIO_IMAGEM_OBTER_POR_ID,
    restauranteCardapioImagemRemover: process.env.REACT_APP_REST_RESTAURANTE_CARDAPIO_IMAGEM_REMOVER,

    restauranteCardapioComida: process.env.REACT_APP_REST_RESTAURANTE_CARDAPIO_COMIDA,
    cardapiosComidaPorUsuario: process.env.REACT_APP_REST_RESTAURANTE_CARDAPIO_COMIDA_POR_USUARIO,

    restauranteCardapioBebida: process.env.REACT_APP_REST_RESTAURANTE_CARDAPIO_BEBIDA,
    cardapiosBebidaPorUsuario: process.env.REACT_APP_REST_RESTAURANTE_CARDAPIO_BEBIDA_POR_USUARIO,

    usuario: process.env.REACT_APP_REST_USUARIO,
    perfil: process.env.REACT_APP_REST_USUARIO_PERFIL,
    usuariosRestaurante: process.env.REACT_APP_REST_USUARIO_RESTAURANTE,

    autenticacao: process.env.REACT_APP_REST_AUTENTICACAO,
    autenticacaoAutenticar: process.env.REACT_APP_REST_AUTENTICACAO_AUTENTICAR,
    autenticacaoValidarToken: process.env.REACT_APP_REST_AUTENTICACAO_VALIDAR_TOKEN,
    autenticacaoStorage: process.env.REACT_APP_REST_AUTENTICACAO_STORAGE,
    autenticacaoCriarConta: process.env.REACT_APP_REST_AUTENTICACAO_CRIAR_CONTA,
    autenticacaoEsqueceuSenha: process.env.REACT_APP_REST_AUTENTICACAO_ESQUECEU_SENHA,
}

export default rest;
