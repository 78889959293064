import ListView from "../../component/ListView";
import {Column, ColumnBodyOptions} from "primereact/column";
import useToken from "../../hook/useToken";
import React, {useContext, useState} from "react";
import {TipoUsuario} from "../../model/TipoUsuario";
import rest from "../../utils/EnvConfig";
import moment, {Moment} from "moment/moment";
import Diaria from "../../model/Diaria";
import DinheiroUtils from "../../utils/DinheiroUtils";
import HorarioUtils from "../../utils/HorarioUtils";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import styles from "../restaurante/Restaurante.module.css";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import DiariaCandidato from "../../model/DiariaCandidato";
import {Ajax} from "../../utils/Ajax";
import {MensagemContext} from "../../context/MensagemContext";
import {MensagemContextType} from "../../context/MensagemContextType";
import {Message} from "primereact/message";
import {confirmDialog} from "primereact/confirmdialog";

function DiariaList() {
    const {token} = useToken();
    const {error, info} = useContext(MensagemContext) as MensagemContextType;
    const horarioUtils = new HorarioUtils();
    const [candidatosDialog, setCandidatosDialog] = useState<boolean>(false);
    const [candidatosLoading, setCandidatosLoading] = useState<boolean>(false);
    const [diariaSelecionada, setDiariaSelecionada] = useState<Diaria>();
    const [candidatosDiaria, setCandidatosDiaria] = useState<DiariaCandidato[]>([]);
    const [existeAprovado, setExisteAprovado] = useState<boolean>(false);
    const [aprovarCandidatoVisible, setAprovarCandidatoVisible] = useState<boolean>(false);

    const obterRecursoCorreto = (): string | undefined => {
        if (token && token.tipo === TipoUsuario.ADMINISTRADOR) {
            return `${rest.diaria}/${rest.obterTodos}`;
        }

        if (token && token.tipo === TipoUsuario.CLIENTE) {
            return `${rest.diaria}/diaria/usuario/${token.usuarioUuid}`;
        }

        return "";
    }

    const statusDiariaTemplate = (diaria: Diaria): string => {
        return diaria.status ? "Ativo" : "Inativo";
    };

    const dataDiariaTemplate = (diaria: Diaria): string => {
        if (diaria.data) {
            const novaData: Moment = moment(diaria.data, "YYYY-MM-DD");
            return novaData.format("DD/MM/YYYY");
        }
        return "";
    };

    const quantidadeHorasTemplate = (diaria: Diaria) => {
        if (diaria.horaInicio && diaria.horaFim) {
            return `${horarioUtils.calcularDiferencaEntreHoras(diaria.horaInicio, diaria.horaFim).toFixed(2)}h`;
        }
        return "0h"
    };

    const valorDiariaTemplate = (diaria: Diaria) => {
        if (diaria.valorPagamento) {
            return DinheiroUtils.formatToDinheiro(diaria.valorPagamento);
        }
        return "A combinar";
    };

    const restaurantesDiariaTemplate = (diaria: Diaria) => {
        if (diaria.restaurantes) {
            return diaria.restaurantes.map(r => r.nomeEstabelecimento)
                        .reduce((v, acc) => v + ", " + acc);
        }
        return "Nenhum restaurante";
    }

    const carregarCandidatosPorDiaria = (diaria: Diaria) => {
        setCandidatosDiaria([]);
        setCandidatosLoading(true);
        setDiariaSelecionada(diaria);
        setCandidatosDialog(true);

        Ajax.of()
            .error(error)
            .obterTodosCustom(`${rest.diariaCandidato}/${rest.candidatosPorDiaria}/${diaria.uuid}`,
            (data: DiariaCandidato[]) => {
                setCandidatosLoading(false);
                setCandidatosDiaria(data);
                setExisteAprovado(data.filter(d => d.aprovado).length > 0);
            },
           (error: any) => setCandidatosLoading(false));
    }

    const botoesExtra = (entidade: Diaria, rowIndex?: number): React.JSX.Element[] => {
        return [
            (
                <>
                    <Button icon={PrimeIcons.USERS} className={`${styles.espacamentoBotoes} mr-2`} raised
                            title={"Candidatos"}
                            onClick={(event: any) => carregarCandidatosPorDiaria(entidade)}
                            severity="success" />
                </>
            )];
    }

    const botoesCandidatosGridTemplate = (rowData: DiariaCandidato, options: ColumnBodyOptions) => {
        return (
            <div className="flex align-items-center">
                {rowData.dataAprovacao && rowData.aprovado && (
                    <Message severity="success" text="Aprovado" />
                )}

                {!rowData.dataAprovacao && !rowData.aprovado && existeAprovado && (
                    <Message severity="error" text="Reprovado" />
                )}

                <Button icon={PrimeIcons.CHECK} label={"Aprovar"} title={`Aprovar candidato`}
                        severity="success" raised visible={!existeAprovado}
                        disabled={!rowData.diaria?.status}
                        onClick={(e: any) => aprovarCandidatoConfirm(rowData)}/>
            </div>
        );
    };

    const aprovarCandidatoConfirm = (candidato: DiariaCandidato) => {
        confirmDialog({
            id: `aprovarCandidato${candidato.id}`,
            header: "Aprovar candidato",
            message: 'Tem certeza que deseja aprovar esse candidato?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Sim",
            rejectLabel: "Não",
            async accept()  {
                await aprovarCandidato(candidato);
            },
        });
    };

    const aprovarCandidato = async (candidato: DiariaCandidato) => {
        await Ajax.of().postRegistroCustom(`${rest.server}${rest.diariaCandidato}/${rest.diariaCandidatoAprovar}${candidato.uuid}`, {}, async (response: any) => {
            info(`Candidato '${candidato.nomeUsuarioFirebase}' aprovado com sucesso. Os outros candidatos foram reprovados.`);
            carregarCandidatosPorDiaria(candidato.diaria!);
        }, (err: any) => {
            error(err);
        });
    }

    return (
        <>
            <Dialog header={"Candidatos"} visible={candidatosDialog} style={{width: '90vw'}}
                    onHide={() => {
                        if (!candidatosDialog) return;
                        setCandidatosDialog(false);
                    }}>
                <div className="grid">
                    <div className="col-3">
                        <div className="p-1 font-bold">
                            Restaurante:
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="p-1">
                            {diariaSelecionada?.restaurantes!
                                .map(r => r.nomeEstabelecimento)
                                .reduce((v, acc) => v + ", " + acc)}
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="p-1 font-bold">
                            Data:
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="p-1">
                            {moment(diariaSelecionada?.data, "YYYY-MM-DD").format("DD/MM/YYYY")}
                        </div>
                    </div>

                    <div className="col-3">
                        <div className="p-1 font-bold">
                            Observação:
                        </div>
                    </div>
                    <div className="col-9">
                        <div className="p-1">
                            {diariaSelecionada?.observacao}
                        </div>
                    </div>
                </div>

                <br />

                <DataTable value={candidatosDiaria} tableStyle={{ minWidth: '50rem' }}
                           loading={candidatosLoading}
                           emptyMessage={"Nenhum registro encontrado"}>
                    <Column field="nomeUsuarioFirebase" header="Candidato"></Column>
                    <Column field="emailUsuarioFirebase" header="E-mail"></Column>
                    <Column field="comentario" header="Comentário"></Column>

                    <Column body={botoesCandidatosGridTemplate} header={""} style={{ width: '140px'}} />
                </DataTable>
            </Dialog>

            <ListView title={"Diária"}
                      botoesAdicionais={botoesExtra}
                      restCustom={obterRecursoCorreto()}
                      id={`${rest.diaria}`}>
                <Column field="restaurantes" header="Restaurantes" sortable body={restaurantesDiariaTemplate}/>
                <Column field="tipoServico.nomeServico" header="Tipo do serviço" sortable/>
                <Column field="data" header="Data" sortable body={dataDiariaTemplate}/>
                <Column field="quantidadeHoras" header="Quantidade de horas" body={quantidadeHorasTemplate} sortable />
                <Column field="valorPagamento" header="R$ Valor" sortable body={valorDiariaTemplate} />
                <Column field="status" header="Situação" sortable body={statusDiariaTemplate} />
            </ListView>
        </>
    );
}

export default DiariaList;